.notifications-list-wrap .ant-list-vertical .ant-list-item {
    padding: 15px;
}

.notifications-list-wrap .ant-list-vertical .ant-list-item-action {
    margin-top: 5px;
}

.notifications-list-wrap .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 0px;
}
