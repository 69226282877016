.App {
    /*text-align: center;*/
}

/* Hide scrollbar for Chrome Safari */
/* .App div::-webkit-scrollbar, */
.App p::-webkit-scrollbar,
.App span::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
/* .App div, */
.App p,
.App span {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

header.ant-layout-header {
    padding: 0 25px;
    display: flex;
}

.logo-container img {
    width: 140px;
}

.logo-container {
    padding-right: 2rem;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 300px;
    height: 300px;
}

.chrome-picker {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 2px, rgba(0, 0, 0, 0.08) 0px 4px 8px !important;
}

.graphql-voyager {
    position: relative;
}

.graphql-voyager .type-doc > .type-info-popover {
    display: none;
}

.donations-action-btn {
    width: 174px;
    height: 32px;
    font-size: 14px;
    text-align: center;
    border-radius: 2px;
}
