.ant-table-content {
    overflow-x: scroll;
}

.ant-table-content .ant-table-body {
    min-width: 800px;
    background: white;
}

.ant-drawer-body {
    padding: 0;
}

.ant-drawer-header {
    background-color: #012141;
    border-bottom: #012141;
}

.ant-drawer-header .ant-drawer-title, .ant-drawer-header .anticon svg {
    color: white;
}

.menu-wrap-fixed {
    position: fixed;
    overflow-y: scroll;
    height: calc(100% - 48px);
    width: 200px;
}

header.ant-layout-header.mobileHeader {
    display: none;
}

.logo-container-desktop {
    padding: 1rem 1.5rem .5rem;
}

.logo-container-desktop .logo {
    width: 140px;
}

.logo-container-desktop .logo-sm {
    width: 32px;
}

@media (max-width: 575px) {
    header.ant-layout-header.mobileHeader {
        display: flex;
    }

    .sideBar {
        display: none;
    }

    .logo-container-desktop {
        display: none;
    }
}
